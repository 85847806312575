import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import { 
  graphql, 
  useStaticQuery 
} from "gatsby"
import Img from 'gatsby-image'
import { Container } from "react-bootstrap"

const HomeCarousel = () => {

  const data = useStaticQuery(graphql`
  {
    allContentfulOffertaMensile {
      edges {
        node {
          foto {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          descrizioneOfferta
        }
      }
    }
  }
`)

  const Slides = data.allContentfulOffertaMensile.edges.map(({node}, index) => {
    return (
      <Carousel.Item>
        <Img
        className="rounded d-block w-100"
        fluid={node.foto.fluid}
        alt={`slide ${index+1}`}
        />
        <Carousel.Caption>
          <h4><mark>{node.descrizioneOfferta}</mark></h4>
        </Carousel.Caption>
      </Carousel.Item>
    )
  })

  return (
    <Container className="mb-5">
      <Carousel className="shadow-lg mt-3">
        { Slides }
      </Carousel>
    </Container>
  )
}

export default HomeCarousel;

