import React from 'react';
import {
  Card, 
  CardImg,  
  CardDeck, 
  CardImgOverlay,
  Row,
  Container, 
  CardLink
} from 'reactstrap';

const HomeServices = () => {
  return (
    <Container className="mt-5">
    <Row>
      <CardDeck>
        <Card inverse className="shadow">
          <CardImg style={{filter: "blur(1px)"}} width="100%" src="https://images.unsplash.com/photo-1562003047-f0782be3d797?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80" alt="Card image cap" />
          <CardImgOverlay>
            <CardLink href="/services#first" className="text-center" style={{ color: "white", textDecoration: "none" }}><h4 style={{ marginTop: "25%", marker: '#000000' }}>AUTORIPARAZIONI</h4></CardLink>
          </CardImgOverlay>
        </Card>
        <Card inverse className="shadow">
          <CardImg style={{filter: "blur(1px)"}} width="100%" src="https://images.unsplash.com/photo-1578844251758-2f71da64c96f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80" alt="Card image cap" />
          <CardImgOverlay>
          <CardLink href="/services#second" className="text-center"><h4 style={{ color: "white", marginTop: "25%", marker: '#000000' }}>GOMMISTA</h4></CardLink>
          </CardImgOverlay>
        </Card>
        <Card inverse className="shadow">
          <CardImg style={{filter: "blur(1px)"}} width="100%" src="https://images.unsplash.com/photo-1571348500628-1e9b6aa00dba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80" alt="Card image cap" />
          <CardImgOverlay>
          <CardLink href="/services#second" className="text-center"><h4 style={{ color: "white", marginTop: "25%", marker: '#000000' }}>ELETTRAUTO</h4></CardLink>
          </CardImgOverlay>
        </Card>
      </CardDeck>
      </Row>
    </Container>
  );
};

export default HomeServices;