import React from "react"
import {
  Container,
  Row
} from "reactstrap"

const HomeMap = () => {
  return (
    <Container className="mt-5 mb-5">
      <h4 className="text-center"><b>Dove trovarci</b></h4>
      <h5 className="text-center">Via Toscana, 20, 25069 Villa Carcina (BS)</h5>
      <h6 className="text-center">Siamo aperti tutti i giorni dal lunedì al venerdì. Orario dalle 08:00 alle 18:30. È gradita la prenotazione.</h6>
      <Row className="rounded shadow justify-content-center mt-4 mb-5">
        <iframe
          title="review"
          src="https://maps.google.com/maps?q=Autoriparazioni+pit+stop+villa+carcina+&output=embed"
          width="100%"
          height="270px"
          frameBorder="0"
        />
      </Row>
    </Container>
  )
}

export default HomeMap;