import React from "react"
import { 
    useStaticQuery, 
    graphql, 
    Link 
} from "gatsby"
import { 
    Col, 
    Row, 
    Container, 
    Button 
} from 'reactstrap'
import Img from "gatsby-image"
import FsLightbox from 'fslightbox-react'

import "../../styles/_gallery.scss"

const HomeGalleryAlt = () => {
    const [lightboxController, setLightboxController] = 
    React.useState({
      toggler: false,
      slide: 1
    });
      
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    const data = useStaticQuery(graphql`
    {
        allContentfulImmagineGalleria(limit: 4, sort: {fields: foto___createdAt}) {
            edges {
                node {
                    foto {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
    }
    `)

    const Images = data.allContentfulImmagineGalleria.edges.map(({node}, index) => {
        return (
            <Col xs="6" sm="6" md="6" lg="6" xl="3" className="mb-3">
                <div className="mb-2 imgContainer" onClick={()=>openLightboxOnSlide(index+1)}>
                    <Img className="shadow-lg rounded" fluid={node.foto.fluid} alt={`slide ${index+1}`}/>
                    <div className="overlay">
                        {/*<div className={GalleryStyles.text}>Clicca per ingrandire</div> */}
                    </div>
                </div>
            </Col>
        )
    })

    const img = data.allContentfulImmagineGalleria.edges.map(({node}, index) => {
        return (node.foto.fluid.src)
    })

    return (
        <Container className="mt-5">
            <h4 className="text-center"><b>I nostri scatti</b></h4>
            <Row>
                { Images }
            </Row>
            <FsLightbox
            toggler={lightboxController.toggler}
            sources={img}
            slide={lightboxController.slide}
            />
            <Row className="justify-content-center mb-5">
                <Link to='/gallery'>
                    <Button className="rounded shadow" style={{ backgroundColor:'#080a7c' }}>
                        Galleria completa
                    </Button>
                </Link>
            </Row>
        </Container>
    )
    }

    export default HomeGalleryAlt;

  