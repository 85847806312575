import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { 
  Row, 
  Container, 
  Button,
} from "react-bootstrap"
import HomeMap from "../components/index/homeMap"
import HomeCarousel from "../components/index/homeCarousel"
import HomeServices from "../components/index/homeServices"
import HomeGallery from "../components/index/homeGallery"

export default function IndexPage({data}) {
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`auto`, `riparazioni`, `pitstop`]} />
      <HomeCarousel />
      <Container>
        <HomeServices />
        <Row className="justify-content-center mt-3 mb-5">
          <Link to='/services'>
            <Button className="rounded shadow" style={{ backgroundColor:'#080a7c' }}>
            Tutti i servizi
            </Button>
          </Link>
      </Row>
      </Container>
      <HomeGallery />
      <HomeMap />
    </Layout>
  )
}
